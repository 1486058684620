<template>
   <v-container fluid>
      <v-row>
         <v-col cols="12">
            <v-card class="" elevation="" max-width="">
               <v-card-title class="justify-center"> NUEVO PRODUCTO </v-card-title>
               <v-divider></v-divider>
               <v-card-text>
                  <v-row>
                     <v-col cols="12">
                        <p>
                           <router-link exact="" style="text-decoration: none" :to="{ name: 'inicioPanel' }">Inicio</router-link>
                           /
                           <router-link exact="" style="text-decoration: none" :to="{ name: 'lentesContactoPanel' }">Productos</router-link>
                           /
                           <router-link exact="" style="text-decoration: none" :to="{ name: 'lentesContactoCrearPanel' }">Crear</router-link>
                        </p>
                     </v-col>

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>
                  </v-row>

                  <v-row>
                     <v-col cols="12 text-center" v-show="!mostrarContenido">
                        <v-progress-circular indeterminate color="primary" :size="100" :width="7"></v-progress-circular>
                     </v-col>
                  </v-row>

                  <v-form @submit.prevent="guardar()" v-show="mostrarContenido" :disabled="processForm">
                     <v-row>
                        <v-col cols="12">
                           <v-alert type="error" v-model="alertError" dismissible ref="mensajeError">
                              <ul v-for="(errors, index) in listErrors" :key="index">
                                 <li v-text="errors[0]"></li>
                              </ul>
                           </v-alert>
                        </v-col>


                        <v-col cols="12" md="8">
                           <v-text-field dense label="Nombre(*)" v-model="producto.nombre"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                           <v-text-field dense label="Codigo" v-model="producto.codigo"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                           <v-text-field dense label="Sub nombre" v-model="producto.sub_nombre" ></v-text-field>
                        </v-col>


                        <v-col cols="12" md="3">
                           <v-text-field type="number" min="0" dense label="Precio lista" v-model="producto.precio_lista">
                           </v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-text-field type="number" min="0" dense label="Precio" v-model="producto.precio">
                           </v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-text-field type="number" min="0" dense label="Inventario" v-model="producto.inventario">
                           </v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-select label="Marca" v-model="producto.idmarca" :items="resources.marcas" item-text="nombre" item-value="idmarca"  dense >
                           </v-select>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-select label="Tipo de lentes" multiple v-model="producto.lc_tipos" :items="resources.lc_tipos" item-text="nombre" item-value="idlc_tipo"  dense >
                           </v-select>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-select label="Colores" multiple v-model="producto.lc_colores" :items="resources.lc_colores" item-text="nombre" item-value="idlc_color" dense >
                           </v-select>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-text-field label="N° de lentes por caja" v-model="producto.nro_lentes_por_caja" type="number" min="0" dense >
                           </v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-text-field label="Porcentaje de agua" v-model="producto.porcentaje_agua" type="text" dense >
                           </v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="3" class="pt-0">
                           <span class="my-0">Permite Astigmatismo:</span>
                           <v-switch class="mt-0" hide-details v-model="producto.permite_astigmatismo" :true-value="1" :false-value="0" :label="producto.lentes_contacto_astigmatismo? 'SI' : 'NO'" flat >
                           </v-switch>
                        </v-col>
                        
                        <v-col cols="12" md="3" v-if="producto.permite_astigmatismo">
                           <v-text-field label="Cilindro" v-model="producto.cilindro" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="3" v-if="producto.permite_astigmatismo">
                           <v-text-field label="Eje" v-model="producto.eje" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-text-field label="Curva base" v-model="producto.curva_base" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>
   
                        <v-col cols="12" md="6">
                           <v-textarea dense hide-details rows="1" label="Poder" v-model="producto.poder" type="text" ></v-textarea>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-text-field label="Diámetro" v-model="producto.diametro" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-text-field label="Orden Marca" v-model="producto.orden_marca" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-text-field label="Orden Tipo" v-model="producto.orden_tipo" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="3">
                           <v-text-field label="Esfera" v-model="producto.esfera" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>

                        <v-col cols="12">
                           <v-textarea dense hide-details rows="1" label="Material y porcentaje del contendio" v-model="producto.informacion_adicional" outlined>
                           </v-textarea>
                        </v-col>

                        <v-col cols="12">
                           <v-textarea dense hide-details label="Funcionalidad" v-model="producto.funcionalidad" outlined rows="2">
                           </v-textarea>
                        </v-col>

                                             
                        <v-col cols="12">
                           <v-textarea dense hide-details rows="1" label="Detalle de contenido" v-model="producto.detalle_contenido" outlined></v-textarea>
                        </v-col>

                        <v-col cols="12">
                           <v-textarea dense hide-details label="Descripción" v-model="producto.descripcion" outlined></v-textarea>
                        </v-col>

                        <v-col cols="12">
                           <p class="my-0">Contenido</p>
                           <ckeditor
                              :editor-url="apiUrl('js/ckeditor/ckeditor.js')"
                              v-model="producto.contenido"
                              :config="{
                              filebrowserBrowseUrl: '/plugin_externos/ckfinder/ckfinder.html',
                              allowedContent: true,
                              }"
                           ></ckeditor>
                        </v-col>
                        
                        
                        <v-col cols="12">
                           <p>Imagenes:</p>
                           <p class="text-center">
                              <v-btn
                                 :loading="loaderImagen"
                                 color="primary"
                                 @click="
                                    $refs.iptImagen.value = '';
                                    $refs.iptImagen.click();
                                 "
                              >
                                 <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR IMAGENES
                              </v-btn>
                           </p>
                           <input style="display: none" multiple type="file" ref="iptImagen" @change.prevent="cargarImagenes" accept="image/*" />
                           <div class="custom-my-dropzone">
                              <v-row v-show="producto.imagen.length > 0">
                                 <v-col cols="12">
                                    <v-simple-table dense="" fixed-header="">
                                       <template v-slot:default>
                                          <thead class="text-no-wrap">
                                             <tr>
                                                <th>MOVER</th>
                                                <th>ORDEN</th>
                                                <th>IMAGEN</th>
                                                <th>ORIENTACIÓN</th>
                                                <th>ELIMINAR</th>
                                             </tr>
                                          </thead>
                                          <draggable v-model="producto.imagen" tag="tbody" handle=".cursor-move">
                                             <tr v-for="(img, index) in producto.imagen" :key="index">
                                                <td class="cursor-move">
                                                   <v-icon>mdi-drag-variant</v-icon>
                                                </td>
                                                <td v-text="index + 1"></td>
                                                <td>
                                                   <v-img height="120" width="100" :src="transformarArchivoUrl(img)" contain></v-img>
                                                </td>
                                                <td v-if="index == 0">FRONTAL</td>
                                                <td v-else-if="index == 1">PERFIL</td>
                                                <td v-else-if="index == 2">LATERAL</td>
                                                <td v-else-if="index == 3">CENITAL</td>
                                                <td v-else>OTROS</td>
                                                <td>
                                                   <v-btn icon color="red" small @click.prevent="eliminarImagen(index)">
                                                      <v-icon>mdi-trash-can</v-icon>
                                                   </v-btn>
                                                </td>
                                             </tr>
                                          </draggable>
                                       </template>
                                    </v-simple-table>
                                 </v-col>
                              </v-row>
                           </div>
                        </v-col>

                        <v-col cols="12">
                           <p>Imagen Receta:</p>
                           <input style="display: none" type="file" ref="imgReceta" @change.prevent="cargarImgReceta" accept="image/*" />
                           <div class="custom-my-dropzone">
                              <v-row>
                                 <v-col cols="12" class="text-center" v-show="urlImgReceta === ''">
                                    <v-btn
                                       :loading="loaderImgReceta"
                                       color="primary"
                                       @click="
                                          $refs.imgReceta.value = '';
                                          $refs.imgReceta.click();
                                       "
                                    >
                                       <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR IMAGEN RECETA
                                    </v-btn>
                                 </v-col>
                                 <v-col cols="12" class="text-center" v-show="urlImgReceta !== ''">
                                    <v-btn icon color="red" small @click.prevent="eliminarImgReceta">
                                       <v-icon>mdi-trash-can</v-icon>
                                    </v-btn>
                                    <v-img :src="urlImgReceta" height="200" contain> </v-img>
                                 </v-col>
                              </v-row>
                           </div>
                        </v-col>
                    

                        <v-col cols="12">
                           <v-select dense item-text="texto" item-value="valor" :items="resources.estado" label="Estado(*)" outlined v-model="producto.estado"></v-select>
                        </v-col>
                      

                        <v-col cols="12" class="text-center">
                           <v-btn class="mr-2" type="submit" :loading="processForm" color="success" large=""> <v-icon left="" large="">mdi-content-save</v-icon> GUARDAR </v-btn>
                           <v-btn :to="{ name: 'lentesContactoPanel' }" type="button" :loading="processForm" color="error" large=""> <v-icon left="" large="">mdi-close</v-icon> CANCELAR </v-btn>
                        </v-col>
                     </v-row>
                  </v-form>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
   import draggable from "vuedraggable";

   export default {
      components: {
         draggable,
      },

      data() {
         return {
            resources: {
               marcas: [],
               lc_colores: [],
               lc_tipos: [],
               estado: [
                  {
                     texto: "Habilitado",
                     valor: 1,
                  },
                  {
                     texto: "Inhabilitado",
                     valor: 0,
                  },
               ],
            },
            producto: {
               codigo: "",
               nombre: "",
               sub_nombre: "",
               descripcion: "",
               contenido: "",
               inventario: "",
               precio: "",
               precio_lista: "",
               estado: 1,
               imagen: [],
               
               lc_colores: [],
               lc_tipos: [],
               idmarca: null,
               nro_lentes_por_caja: '',
               porcentaje_agua: '',
               permite_astigmatismo: '',
               cilindro: '',
               eje: '',
               curva_base: '',
               poder: '',
               diametro: '',
               orden_marca: '',
               orden_tipo: '',
               esfera: '',
               informacion_adicional: '',
               detalle_contenido: '',
               funcionalidad: '',
            },
            urlImgReceta: "",
            loaderImgReceta: false,
            mostrarContenido: false,
            processForm: false,
            listErrors: [],
            alertError: false,
            loaderImagen: false,
         };
      },
      computed: {
      },
      methods: {

         cargarImgReceta(event) {
            this.loaderImgReceta = true;

            if (this.urlImgReceta !== "") {
               this.$toasted.error("Ya hay un archivo cargado");
               this.loaderImgReceta = false;
               return;
            }

            if (event.target.files.length > 0) {
               let imagen = event.target.files[0];
               this.urlImgReceta = URL.createObjectURL(imagen);
               this.producto.imagen_receta = imagen;
            }

            this.loaderImgReceta = false;
         },

         eliminarImgReceta() {
            this.urlImgReceta = "";
            this.producto.imagen_receta = "";
         },

         cargarImagenes(event) {
            this.loaderImagen = true;

            if (event.target.files && event.target.files.length > 0) {
               let imagen = event.target.files;
               Object.values(imagen).forEach((value) => this.producto.imagen.push(value) );
               // this.producto.imagen.push(...Object.values(imagen));
            }

            this.loaderImagen = false;
         },

         eliminarImagen(index) {
            this.$delete(this.producto.imagen, index);
         },

         transformarArchivoUrl(imagen) {
            return URL.createObjectURL(imagen);
         },

         getDatos() {
            this.axios({
               method: "GET",
               url: "api/panel/lentes-de-contacto/create",
            })
               .then((response) => {
                  const data = response.data;

                  this.resources.marcas = data.marcas;
                  this.resources.lc_colores = data.lc_colores;
                  this.resources.lc_tipos = data.lc_tipos;

               })
               .catch((error) => {
                  console.log(error);
               })
               .finally(() => {
                  this.mostrarContenido = true;
               });
         },

         guardar() {
            this.alertError = false;
            this.processForm = true;

            this.axios({
               method: "POST",
               url: "api/panel/lentes-de-contacto",
               data: this.serialize(this.producto),
            })
               .then((response) => {

                  this.$toasted.success(response.data.data, {
                     icon: "mdi-check-bold",
                  });
                  this.$router.push({ name: "lentesContactoPanel", });

               })
               .catch((error) => {
                  let status = error.response.status;
                  if (status === 422) {
                     let arrErrors = error.response.data.errors;
                     this.listErrors = Object.values(arrErrors);
                     this.alertError = true;
                     this.$vuetify.goTo(this.$refs.mensajeError);
                  } else {
                     this.$toasted.error("Ocurrio un error al guardar este registro", {
                        icon: "mdi-close",
                     });
                  }
               })
               .finally(() => {
                  this.processForm = false;
               });
         },
      },

      created() {
         if (!this.validarPermiso("productos.crear")) {
            this.$toasted.error("Usted no tiene permisos para esta sección", { icon: "mdi-close", });
            this.$router.push({ name: "inicioPanel", });
         }

         window.scrollTo(0, 0);
         this.getDatos();
      },
   };
</script>

<style scoped>
   .cursor-move {
      cursor: move;
   }
</style>
